<template>
  <div class="app-item app-content">
    <div class="top-title flex">
      <h4>我的文库</h4>
      <router-link :to='{name:"Publish"}'>
        <el-button type="primary" v-if="btnShow" size="small">
          发布
          <i class="el-icon-upload el-icon--right"></i>
        </el-button>
      </router-link>
    </div>
    <div class="app-item-nav">
      <el-menu default-active="0" mode="horizontal" >
        <el-menu-item class="content_nav_item" @click="search('all')" index="0">全部文库</el-menu-item>
        <template v-for="(item, index) in tags">
          <el-menu-item :index="((index+1) + '')" :key="index" @click="search(item.id)">{{item.tag_name}}</el-menu-item>
        </template>
      </el-menu>
    </div>
    <el-row :gutter="10">
      <el-col
        :xs="24"
        :sm="24"
        :md="11"
        :lg="11"
        :xl="12"
        class="content-item"
        v-for="(item,index) of list"
        :key="index"
      >
        <div class="grid-content bg-purple clearfix">
          <el-col :xs="7" :sm="4" :md="6" :lg="6" :xl="6">
            <img :src="item.cover" alt>
          </el-col>
          <el-col :xs="13" :sm="16" :md="12" :lg="12" :xl="12" class="app-content-item">
            <p>{{item.title}}</p>
            <span>{{item.digest}}</span>
          </el-col>
          <el-col :xs="4" :sm="4" :md="6" :lg="6" :xl="6" class="app-content-date">
            <p>{{item.pub_date.slice(8)}}</p>
            <span>{{item.pub_date.slice(0,7)}}</span>
          </el-col>
          <div class="item-choice">
          <ul>
            <li @click="seeArticle(item.id)">查看</li>
            <li @click="getArticle(item)" v-if="btnShow">修改</li>
            <li @click="deleteArticle(item.id)" v-if="btnShow">删除</li>
            <li @click="getRecords(item)" v-if="btnShow" class="item-record">阅读记录</li>
          </ul>
        </div>
        </div>
        <el-badge :value="item.status"  class="item">
        </el-badge>
        <!-- <i >{{item.status}}</i> -->
      </el-col>
    </el-row>
    <Page :total="total" @goTo="goTo" v-if='isShow'></Page>
  </div>
</template>
<script>
import Page from '../../components/Page.vue'
export default {
  components: {
    Page
  },
  data () {
    return {
      tags: [],
      list: [],
      total: 0,
      page: 1, // 当前页面
      lastPage: 1, // 最后一页
      store: {},
      isShow: true,
      btnShow: true
    }
  },
  methods: {
    // 获取tag标签
    getTags () {
      this.$store.dispatch('getProduct').then(res => {
        console.log('tag:', res)
        this.tags = res
      })
    },
    search (id) {
      if (id === 'all') {
        this.getStoreArticles(`/api/web/store/articles`)
      } else {
        this.getStoreArticles(`/api/web/store/articles?tag_id=${id}`)
      }
    },
    // 获取我的主页信息
    getStoreArticles (url) {
      this.$store.dispatch('getStoreArticles', url).then(res => {
        // console.log(res.data.length)
        if (url) {
          this.list = []
        }
        if (res.length <= 0 || res.data.length <= 0) {
          this.isShow = false
        } else {
          this.list = res.data
          // if (this.list.status) {
          this.list.forEach((ele, ind) => {
            if (ele.status === 1) {
              ele.status = '成功'
            } else if (ele.status === 0) {
              ele.status = '审核中'
            } else {
              ele.status = '失败'
            }
          })
          // }
          this.total = res.total
        }
      })
    },
    // 翻页
    goTo (page) {
      this.getArticles(`/api/web/store/articles?page=${page}`)
    },
    // 获取单个文章 （修改）
    getArticle (item) {
      this.$router.push({ name: 'Publish', query: { id: item.id } })
    },
    // 查看文章
    seeArticle (id) {
      this.$router.push({ name: 'StoreDetail', params: { id } })
    },
    // 删除文章
    deleteArticle (id) {
      this.$store.dispatch('deleteArticles', id).then(res => {
        if (res.code === 0) {
          this.$message({
            message: res.message,
            type: 'success'
          })
          this.getStoreArticles()
        }
      })
    },
    // 阅读记录
    getRecords (item) {
      this.$router.push({ name: 'Viewrecord', query: { id: item.id } })
    }
  },
  mounted () {
    this.getTags()
    this.getStoreArticles()
    // 判断是不是商家
    this.$store.dispatch('getInfo').then(res => {
      // console.log(res)
      this.store = res.info.store
      if (
        !(
          this.store &&
          this.store.status > 0
        )
      ) { this.btnShow = !this.btnShow }
    })
  }
}
</script>
<style lang="less" scoped>
.app-item {
  .top-title {
    justify-content: space-between;
  }
  // 导航
  .app-item-nav{
    margin-bottom: 20px;
    .el-menu{
      border-bottom: none;
      .el-menu-item{
        border:1px solid #eee;
        border-bottom-color:#eee !important;
        padding:0 0.6rem;
        line-height: 1.5rem;
        white-space: normal;
        .flex
      }
      .is-active{
      background-color: @global-head-active-color !important;
      border-bottom: none;
      color: @global-white !important;
      border:1px solid @global-head-active-color !important
      }
    }
  }
  .grid-content {
    display: flex;
    align-items: center;
    position: relative;
  }
  .item-choice{
    background: url('../../assets/images/dark_bg.png');
    color:#fff;
    position: absolute;
    left:0;
    top:0;
    width: 100%;
    height: 100%;
    text-align: center;
    opacity: 0;
    transform: scale(0,1);
    transition: opacity 0.35s, transform 0.35s;
    ul{
      display: flex;
      flex-direction: column;
      height: 100%;
      padding-left:0;
      margin:0;
      li{
        width:100%;
        flex: 1;
        .flex;
        cursor: pointer;
        // font-size: 16px
      }
      .item-record{
        font-size: 14px
      }
    }

  }
  .grid-content:hover .item-choice{
    opacity: 0.61;
    transform: scale(1)
  }
  .content-item {
    background: #f5f5f5;
    margin: 10px 10px;
    padding: 10px 0;
    position: relative;
    // overflow: hidden;
    .el-badge{
      position: absolute;
      top:-10px;
      right:-12px;
      font-size: 14px
    }
    img {
      width: 80px;
      height: 100px;
    }
    .app-content-item {
      p {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        margin-bottom: 10px;
        color: #3b3b3b;
        font-weight: bold;
        font-size: 16px;
        letter-spacing: 1px;
      }
      span {
        font-size: 14px;
        color: #c0c0c1;
        line-height: 23px;
        letter-spacing: 1px;
        margin-bottom: 10px;
        display: -webkit-box;
        -webkit-line-clamp: 3;
        overflow: hidden;
        -webkit-box-orient: vertical;
      }
    }
    .app-content-date {
      border-left: 2px solid #e6e6e6;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      margin: 20px 0;
      p {
        color: #3f3f3c;
        font-size: 28px;
      }
      span {
        font-size: 12px;
      }
    }
  }
}
@media screen and (max-width: 700px) {
  .app-item{
    padding: 0;
    .content-item {
    margin: 10px 0;
    }
    .el-row{
    padding:0 1rem
    }
    .app-item-nav{
      .el-menu--horizontal{
        .flex;
        justify-content: space-between
      }
      .el-menu-item{
        padding:0 3px !important;
        width: 2.3rem !important;
        height:3rem;
        line-height: 1.25rem
      }
    }
  }
}

@media screen and (max-width: 470px) {
  .app-item {
    .content-item {
      img {
        width: 50px;
        height: 70px;
      }
    }
    // .app-item-nav{
    //   .el-menu-item{
    //     padding:0 1px !important
    //   }
    // }
  }
}
</style>
